import Headroom from 'headroom.js';

class Header {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-nav');
    this.dom.headroom = new Headroom(this.dom.el, {
      // offset: 100,
    });
  }

  init() {
    this.dom.headroom.init();
  }
}

export default Header;
